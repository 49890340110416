<template>
  <div class="d-flex align-items-center">
    <div v-for="(option, index) in options" :key="option.value" class="custom-control custom-checkbox mr-3">
      <input type="checkbox"
             class="custom-control-input"
             :id="`tax_${option.value}_${key}_${index}`"
             :value="option.value"
             v-model="selectedItems"
      >
      <label class="custom-control-label" :for="`tax_${option.value}_${key}_${index}`">{{ option.label }}</label>
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { orderBy, map } from 'lodash-es'
import inputMixin from '@/modules/forms/components/inputs/inputMixin'
import userData from '@/mixins/user-data'

export default {
  mixins: [inputMixin, userData],
  data () {
    return {
      selectedItems: []
    }
  },
  computed: {
    key () {
      return nanoid()
    },
    options () {
      const taxes = orderBy(this.$store.getters['taxRates/getAvailableTaxes'], ['label'], ['asc'])

      return map(taxes, tax => ({
        value: tax.code,
        label: tax.label
      }))
    }
  },
  created () {
    if (Array.isArray(this.value) && this.value.length > 0) {
      this.selectedItems = [...this.value]
    }

    if (!this.$store.state.taxRates.isFetched) {
      this.$store.dispatch('taxRates/fetch', this.currentStoreId)
    }
  },
  watch: {
    selectedItems (value) {
      this.handleChange(value)
    }
  }
}
</script>
